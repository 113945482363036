<template>
    <v-card flat class="post-card" color="grey darken-3">
        <v-card-text class="post-a-title pa-3">{{$t("advertisement.title")}}</v-card-text>

<!--        <v-card-text class="post-a-text">{{$t("advertisement.desc")}}</v-card-text>-->

        <v-col cols="12">
            <ins class="adsbygoogle"
                 style="display:block"
                 data-ad-format="fluid"
                 data-ad-layout-key="-6t+ed+2i-1n-4w"
                 data-ad-client="ca-pub-5373840786362627"
                 data-ad-slot="2382720560"></ins>
        </v-col>
    </v-card>
</template>


<script>
    export default {
        data() {
            return {
                googleInit: null,
            };
        },
        mounted() {
            let timeout = 200;
            if (this.timeout) timeout = this.timeout;
            this.googleInit = setTimeout(() => {
                if (typeof window !== "undefined")
                    (window.adsbygoogle = window.adsbygoogle || []).push({});
            }, timeout);
        },
        destroyed() {
            if (this.googleInit) clearTimeout(this.googleInit);
        }
    }
</script>